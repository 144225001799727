const initialState = {
  loading: false,
  paused: false,
  error: false,
  errorMsg: "",
  onSale_1:false,
  userMintedAmount_1:0,
  onSale_2:false,
  userMintedAmount_2:0,
  onSale_3:false,
  userMintedAmount_3:0,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        errorMsg: "",
        onSale_1: action.payload.onSale_1,
        userMintedAmount_1: action.payload.userMintedAmount_1,
        onSale_2: action.payload.onSale_2,
        userMintedAmount_2: action.payload.userMintedAmount_2,
        onSale_3: action.payload.onSale_3,
        userMintedAmount_3: action.payload.userMintedAmount_3,
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
