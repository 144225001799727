// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const blockchain = store.getState().blockchain
      let onSale_1 = await store.getState().blockchain.smartContract.methods.getOnSaleByPhaseId(1).call();
      let userMintedAmount_1 = await store.getState().blockchain.smartContract.methods.getUserMintedAmountByPhaseId(1,blockchain.account).call()
      let onSale_2 = await store.getState().blockchain.smartContract.methods.getOnSaleByPhaseId(2).call();
      let userMintedAmount_2 = await store.getState().blockchain.smartContract.methods.getUserMintedAmountByPhaseId(2,blockchain.account).call()
      let onSale_3 = await store.getState().blockchain.smartContract.methods.getOnSaleByPhaseId(3).call();
      let userMintedAmount_3 = await store.getState().blockchain.smartContract.methods.getUserMintedAmountByPhaseId(3,blockchain.account).call()

      dispatch(
        fetchDataSuccess({
          onSale_1,
          userMintedAmount_1,
          onSale_2,
          userMintedAmount_2,
          onSale_3,
          userMintedAmount_3,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
